import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography } from '@material-ui/core'
import { H5, TextSm } from '@system'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    position: 'relative',
    width: '100%',
    height: '300px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    '&::before': {
      backgroundImage: (props) => `url(${props.blok.bgImage})`,
      backgroundPosition: (props) => props.blok.backgroundPosition || 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      opacity: 0.5,
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
  },
  title: {
    position: 'relative',
    color: theme.palette.common.white,
    display: 'block',
  },
  description: {
    position: 'relative',
    display: 'block',
    color: theme.palette.common.white,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px 10px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      width: (props) => (props.isCustomersModule ? '60%' : '50%'),
      paddingLeft: '50px',
      textAlign: 'left',
    },
  },
  button: {
    margin: '1rem 0rem',
    display: 'block',
    '& button .material-icons': {
      fontSize: '16px',
      padding: '0px 12px',
    },
  },
}))

const CtaWithOverlayModule = (props) => {
  const classes = useStyles(props)
  return (
    <SbEditable content={props.blok}>
      {!!props.blok.bgImage && (
        <Box className={classes.backgroundImage}>
          <ContentContainer>
            <Box className={classes.content}>
              <H5 className={classes.title}>{props.blok.title}</H5>
              <TextSm className={classes.description}>
                {props.blok.description}
              </TextSm>
              <Typography variant="button" className={classes.button}>
                {renderBloks(props.blok.button)}
              </Typography>
            </Box>
          </ContentContainer>
        </Box>
      )}
    </SbEditable>
  )
}

export default CtaWithOverlayModule
